import React, { useState } from 'react'
import { Radio, Slider } from 'antd'
import { CloseOutlined, SettingOutlined } from '@ant-design/icons'
import Input from '../../components/acms-components/Input'
import { formatNumber } from '../../components/acms-components/utils'

const terms = {
  10: {
    label: '10 anos',
    calc: 4500 / 14500,
  },
  15: {
    label: '15 anos',
    calc: 3000 / 13000,
  },
}

const sliderMarks = {
  50: '50',
  75: '',
  100: '100',
  125: '',
  150: '150',
  175: '',
  200: '200',
}

const Calculator = () => {
  const [sellerView, setSellerView] = useState(false)
  const colorStyle = sellerView ? 'secondary' : 'primary'

  const [term, setTerm] = useState(15)
  const [productValue, setProductValue] = useState(0)
  const [refundPct, setRefundPct] = useState(100)
  let [total, setTotal] = useState(0)
  let [refund, setRefund] = useState(0)
  let [cashbackValue, setCashbackValue] = useState(0)

  let summary = {
    Produto: [productValue, '€'],
    Cashback: [cashbackValue, '€'],
    'Total a pagar': [total, '€'],
    '% de reembolso': [refundPct, '%'],
    Reembolso: [refund, '€'],
  }

  const handleChangeTotal = (value) => {
    total = formatNumber(value, 2, true)
    refund = formatNumber((total * refundPct) / 100, 2, true)
    cashbackValue = formatNumber(refund * terms[term].calc, 2, true)
    setProductValue(total - cashbackValue)
    setTotal(total)
    setRefund(refund)
    setCashbackValue(cashbackValue)
  }

  const handleChangeRefund = (value) => {
    refund = formatNumber(value, 2, true)
    cashbackValue = formatNumber(refund * terms[term].calc, 2, true)
    setRefundPct(formatNumber((refund / total) * 100, 2, true))
    setRefund(refund)
    setProductValue(total - cashbackValue)
    setCashbackValue(cashbackValue)
  }
  const handleChangeTerm = (value) => {
    cashbackValue = formatNumber(refund * terms[value].calc, 2, true)
    setTerm(value)
    setProductValue(total - cashbackValue)
    setCashbackValue(cashbackValue)
  }
  const handleChangeRefundPct = (value) => {
    refund = formatNumber((total * value) / 100, 2, true)
    cashbackValue = formatNumber(refund * terms[term].calc, 2, true)
    setRefund(refund)
    setRefundPct(value)
    setProductValue(total - cashbackValue)
    setCashbackValue(cashbackValue)
  }

  const monthlyRefunds = []
  const year = new Date().getFullYear()
  let installment = formatNumber(refund / term, 2, true)
  for (let i = 0; i < term; i++) {
    monthlyRefunds.push(installment)
  }
  monthlyRefunds[monthlyRefunds.length - 1] +=
    refund - monthlyRefunds.reduce((curr, prev) => curr + prev)
  return (
    <>
      <div
        id="styled-tooltip"
        className={`${sellerView ? 'secondary' : 'primary'}`}
      ></div>
      <section className="section">
        <div className="container">
          <button
            className={`btn btn--${
              sellerView ? 'tertiary' : 'secondary'
            } raised icon-btn`}
            style={{ fontSize: 18, position: 'fixed', bottom: 16, right: 16 }}
            onClick={() => setSellerView(!sellerView)}
          >
            {sellerView ? <CloseOutlined /> : <SettingOutlined />}
          </button>
          <div className="section__body">
            <div className="grid">
              <div className="grid__container grid__container--column spacing-16">
                <div className="grid__item">
                  <div className="grid__container spacing-16">
                    <div className="grid__item xs-6">
                      <div className="panel">
                        <div className="panel__header">
                          <h2 className="panel__title">Investimento</h2>
                          <p className="small helper-text">
                            Insira o valor do total do investimento
                          </p>
                        </div>
                        <div className="panel__body --ta-right">
                          <Input
                            value={total}
                            type="number"
                            money={true}
                            inputStyle={{ textAlign: 'right' }}
                            inputClass={`big-input ${
                              sellerView ? 'secondary' : 'primary'
                            }`}
                            handleChange={(e) =>
                              handleChangeTotal(e.target.value)
                            }
                            formControlStyle={{ maxWidth: 130 }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid__item xs-6">
                      <div className="panel">
                        <div className="panel__header">
                          <h2 className="panel__title">Termo</h2>
                          <p className="small helper-text">
                            Selecione a duração do contrato
                          </p>
                        </div>
                        <div className="panel__body">
                          <div className="grid">
                            <Radio.Group
                              onChange={(e) => handleChangeTerm(e.target.value)}
                              value={term}
                              className={`grid__container grid__container--column spacing-16 ${colorStyle}`}
                            >
                              <div className="grid__item">
                                <Radio value={10}>10 anos</Radio>
                              </div>
                              <div className="grid__item">
                                <Radio value={15}>15 anos</Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid__item">
                  <div className="panel">
                    <div className="panel__header">
                      <h2 className="panel__title">Reembolso</h2>
                      <p className="small helper-text">
                        Arraste o ponteiro de % para cálculo automático do valor
                        do reembolso ou edite o valor para cálculo automático da
                        %.
                      </p>
                    </div>
                    <div className="panel__body">
                      <div className="grid">
                        <div className="grid__container spacing-16">
                          <div
                            className="grid__item"
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                              flex: '1 auto',
                            }}
                          >
                            <Slider
                              min={refundPct < 50 ? refundPct : 50}
                              max={refundPct > 200 ? refundPct : 200}
                              {...(refundPct in sliderMarks
                                ? { step: null }
                                : {})}
                              defaultValue={100}
                              onChange={handleChangeRefundPct}
                              marks={sliderMarks}
                              value={refundPct}
                              tooltipVisible
                              tipFormatter={() => {
                                return `${
                                  refundPct % 1 !== 0
                                    ? formatNumber(refundPct, 2)
                                    : refundPct
                                }%`
                              }}
                              style={{ width: '100%' }}
                              className={`${
                                sellerView ? 'secondary' : 'primary'
                              }`}
                              getTooltipPopupContainer={() =>
                                document.getElementById('styled-tooltip')
                              }
                            />
                          </div>
                          <div className="grid__item" style={{ maxWidth: 130 }}>
                            <Input
                              value={refund}
                              type="number"
                              money={true}
                              inputStyle={{ textAlign: 'right' }}
                              inputClass={`big-input ${
                                sellerView ? 'secondary' : 'primary'
                              }`}
                              handleChange={handleChangeRefund}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--related pb-40">
        <div className="container">
          <div className="section__body">
            <h2 className="subtitle_1 --ta-center mb-16">
              {!sellerView ? 'Reembolso' : 'Resumo'}
            </h2>
            <div className="grid">
              <div className="grid__container grid__container--column spacing-16">
                {!sellerView ? (
                  <>
                    {Object.values(monthlyRefunds).map((value, index) => (
                      <div className="grid__item list-item" key={index}>
                        <div className="grid__container --space-between">
                          <div className="grid__item">
                            <div className="grid__container spacing-16">
                              <div className="grid__item text-mutted">
                                # {index + 1}
                              </div>
                              <div className="grid__item">
                                {year + index + 1}
                              </div>
                            </div>
                          </div>
                          <div className="grid__item --t-bold">
                            {formatNumber(value, 2)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {Object.entries(summary).map(
                      ([key, [value, suffix]], index) => (
                        <div className="grid__item list-item" key={index}>
                          <div className="grid__container --space-between">
                            <div className="grid__item">{key}</div>
                            <div
                              className={`grid__item ${
                                index === 0 ? '--t-bolder --t-secondary' : ''
                              }`}
                            >
                              {formatNumber(value, 2)} {suffix}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Calculator
