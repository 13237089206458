import React, { useContext, useEffect, useRef } from 'react'
import Input from '../../components/acms-components/Input'
import Preloader from '../../components/acms-components/Preloader'
import { AuthContext } from './AuthContextProvider'

const LoginForm = () => {
  const {
    errors,
    forgottenPass,
    setForgottenPass,
    resetForgottenPass,
    sendPassChange,
    isLoading,
  } = useContext(AuthContext)

  const handleChange = (e) => {
    setForgottenPass({ ...forgottenPass, [e.target.name]: e.target.value })
  }

  const inputRef = useRef()
  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    sendPassChange()
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)} action="">
      <div className="grid__container grid__container--column spacing-16">
        {forgottenPass.success && (
          <div className="grid__item">
            <div className="p-16 mb-16 bg--success light">
              <p>
                Se o seu email estiver correto, as instruções serão enviadas.
              </p>
              <p>Confira sua Caixa de Entrada e SPAM.</p>
            </div>
          </div>
        )}
        {errors && errors.non_field_errors && (
          <div className="grid__item">
            <div className="p-16 mb-16 bg--alert light">
              {errors.non_field_errors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          </div>
        )}
        <div className="grid__item">
          <h1 className="subtitle_1">Redefinição de senha</h1>
          <p>
            Insira o seu email para enviarmos as instruções para a alteração da
            sua senha.
          </p>
        </div>
        <div className="grid__item">
          <Input
            ref={inputRef}
            handleChange={(e) => handleChange(e)}
            name="email"
            labelText="Email"
            value={forgottenPass.email}
            error={errors?.email}
          />
        </div>
        <div className="grid__item">
          <div className="action-btns --space-between">
            <button
              type="button"
              onClick={resetForgottenPass}
              className="btn btn--primary outlined"
            >
              Voltar
            </button>
            {isLoading ? (
              <Preloader />
            ) : (
              <button className="btn btn--primary raised">Enviar</button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default LoginForm
