import Axios from 'axios'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import Input from '../../components/acms-components/Input'
import Preloader from '../../components/acms-components/Preloader'
import { AuthContext } from './AuthContextProvider'
import { API_ENDPOINT } from './config'
import ForgottenPassForm from './ForgottenPassForm'
import Response from './Response'

const URL_PASS_RECOVER = `${API_ENDPOINT}/password/confirm/`
const axios = Axios.create({ baseURL: `${API_ENDPOINT}` })

const PasswordResetForm = () => {
  const { auth, logged } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState(null)
  const [passData, setPassData] = useState({
    password: '',
    password_confirm: '',
    token: '',
    user_id: '',
  })
  const [isPassTokenValid, setIsPassTokenValid] = useState(true)

  const inputRef = useRef()
  useEffect(() => {
    inputRef.current.focus()
  }, [])
  const handleChange = (e) => {
    setPassData({ ...passData, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (auth.user) passData['user_id'] = auth.user.id
    axios
      .post(`/password/confirm/`, { ...passData })
      .then((res) => {
        setSuccess(true)
        setErrors(null)
        setPassData({
          password: '',
          password_confirm: '',
          token: '',
          user_id: '',
        })
        logged()
      })
      .catch((error) => {
        setErrors({ ...error.response.data })
      })
      .finally(() => setIsLoading(false))
  }

  const getPassToken = useCallback(() => {
    const url = window.location
    if (url.pathname.includes(URL_PASS_RECOVER))
      setPassData((passData) => ({
        ...passData,
        token: url.pathname.slice(URL_PASS_RECOVER.length).replace('/', ''),
      }))
  }, [])

  const checkChangePassChangeToken = useCallback(() => {
    if (passData.token) {
      setIsLoading(true)
      axios
        .post(`/check_reset_pass_token`, {
          token: passData.token,
        })
        .then((res) => setIsPassTokenValid(true))
        .catch((error) => setIsPassTokenValid(false))
        .finally(() => setIsLoading(false))
    }
  }, [passData.token])

  useEffect(() => {
    getPassToken()
    checkChangePassChangeToken()
  }, [checkChangePassChangeToken, getPassToken])

  if (passData.token && !isPassTokenValid)
    return (
      <div className="grid__container">
        <div className="grid__item">
          <Response
            content={<p>O link de redefinição de palavra passe expirou.</p>}
          />
        </div>
        <div className="grid__item">
          <ForgottenPassForm />
        </div>
      </div>
    )

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="grid__container grid__container--column spacing-16">
        {success && (
          <div className="grid__item">
            <div className="p-16 mb-16 bg--success light">
              <p>Senha alterada com sucesso!</p>
              {success && !auth?.isAuthenticated && (
                <>
                  <p>Faça o login com a sua nova senha.</p>
                  <a href="/" className="btn btn--primary raised">
                    Login
                  </a>
                </>
              )}
            </div>
          </div>
        )}
        {errors && errors.non_field_errors && (
          <div className="grid__item">
            <div className="p-16 mb-16 bg--alert light">
              {errors.non_field_errors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          </div>
        )}
        <div className="grid__item">
          <h1 className="subtitle_1">Redefinição de senha</h1>
          <p>Insira a sua nova senha e a confirme.</p>
        </div>
        <div className="grid__item">
          <Input
            ref={inputRef}
            type="password"
            handleChange={(e) => handleChange(e)}
            name="password"
            labelText="Senha"
            value={passData.password}
            error={errors?.password}
          />
        </div>
        <div className="grid__item">
          <Input
            type="password"
            handleChange={(e) => handleChange(e)}
            name="password_confirm"
            labelText="Confirmação de senha"
            value={passData.password_confirm}
            error={errors?.password_confirm}
          />
        </div>
        <div className="grid__item">
          <div className="action-btns --space-between">
            {isLoading ? (
              <Preloader />
            ) : (
              <button className="btn btn--primary raised">
                Confirmar alteração
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default PasswordResetForm
