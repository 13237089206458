import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import AuthContextProvider from './views/Auth/AuthContextProvider'
import Login from './views/Auth/Login'
import ProtectedRoute from './views/Auth/ProtectedRoute'
import Dashboard from './views/Dashboard'

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Route exact path="/login" component={Login} />
        <Route path="/users/auth/password/confirm/" component={Login} />
        <ProtectedRoute exact path="/" component={Dashboard} />
      </BrowserRouter>
    </AuthContextProvider>
  )
}

export default App
