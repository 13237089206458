import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import Preloader from '../../components/acms-components/Preloader'
import { AuthContext } from './AuthContextProvider'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, auth } = useContext(AuthContext)
  if (auth.token && !isAuthenticated) return <Preloader />
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && !auth?.user?.is_new_user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    ></Route>
  )
}

export default ProtectedRoute
