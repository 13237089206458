import { useState } from 'react'

export const useForm = (iniValues) => {
  const [values, setValues] = useState(iniValues)

  return [
    values,
    (e) => setValues({ ...values, [e.target.name]: e.target.value }),
  ]
}
