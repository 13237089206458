export const formatNumber = (
  num,
  decimalPlaces = 2,
  returnNumeric = false,
  thousandSep = '.',
  decimalSep = ',',
  round = false
) => {
  if (!num) return 0
  if (typeof num === 'string') {
    if (num.indexOf('.') < num.length - 3) num = num.replaceAll('.', '')
    if (num.indexOf(',') >= num.length - 3) num = num.replace(',', '.')
    num = parseFloat(num)
  }

  if (round) num = Math.round(num)

  num = num.toFixed(decimalPlaces)

  if (returnNumeric) return parseFloat(num)
  return num
    .replace('.', decimalSep)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSep}`)
}
