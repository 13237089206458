import React, { useContext } from 'react'
import logo from '../assets/img/logo.svg'
// import logo from '../assets/img/logo-safeguard.png'

import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'
import { AuthContext } from '../views/Auth/AuthContextProvider'
import { Link, useHistory, useLocation } from 'react-router-dom'

const Topbar = () => {
  const { logout } = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()

  return (
    <div className="topbar">
      <div className="container grid">
        <div className="grid__container spacing-8 --align-center --space-between">
          {location.pathname.length > 1 && (
            <div className="grid__item">
              <button
                className="btn btn--primary icon-btn"
                onClick={() => history.goBack()}
              >
                <i className="fas fa-arrow-left"></i>
              </button>
            </div>
          )}
          <div className="grid__item">
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="logo"
                style={{ maxHeight: 40 }}
              />
            </Link>
          </div>
          <div className="grid__item">
            <div className="action-btns">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="0">
                      <Link to="/password-reset">Redefinir Senha</Link>
                    </Menu.Item>
                    <Menu.Item key="1">
                      <a href="/">Atualizar Sistema</a>
                    </Menu.Item>
                    <Menu.Item key="2" onClick={logout}>
                      Sair
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <button
                  className={`btn btn--primary icon-btn`}
                  style={{ fontSize: 18 }}
                >
                  <UserOutlined />
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Topbar
