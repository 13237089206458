import React from 'react'
import PasswordResetForm from './PasswordResetForm'

const PasswordReset = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="section__body">
          <PasswordResetForm />
        </div>
      </div>
    </section>
  )
}

export default PasswordReset
