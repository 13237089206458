import React, { useContext, useState } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { AuthContext } from './Auth/AuthContextProvider'
import PasswordReset from './Auth/PasswordReset'
import ProtectedRoute from './Auth/ProtectedRoute'
import Calculator from './Calculator'
import Topbar from '../components/Topbar'

const Dashboard = () => {
  const [sellerView, setSellerView] = useState(false)
  const { auth } = useContext(AuthContext)
  return (
    <div>
      <BrowserRouter>
        {auth.isAuthenticated && (
          <Topbar
            sellerView={sellerView}
            handleClickSellerView={() => setSellerView(!sellerView)}
          />
        )}
        <ProtectedRoute
          exact
          path="/"
          component={Calculator}
          sellerView={sellerView}
        />
        <Route exact path="/password-reset" component={PasswordReset} />
      </BrowserRouter>
    </div>
  )
}

export default Dashboard
