import React, { useState } from 'react'
import NumberFormat from 'react-number-format'

const Input = React.forwardRef(
  (
    {
      placeholder,
      helperText,
      startAdornment,
      endAdornment,
      labelText,
      name,
      mask,
      type = 'text',
      money = false,
      currencySign = '€',
      required = false,
      value,
      handleChange,
      formControlStyle,
      formControlClass = '',
      labelStyle,
      labelClass = '',
      labelAsP = false,
      fieldWrapperStyle,
      fieldWrapperClass = '',
      inputStyle,
      inputClass = '',
      inputPattern = '',
      decimalPlaces = 0,
      error,
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false)
    const [isEmpty, setIsEmpty] = useState(Boolean(value))
    let isNumberInput = false

    labelClass = `float-label ${labelClass}${
      focused || isEmpty ? ' label-up' : ''
    }`
    formControlClass = `form-control ${formControlClass}`
    fieldWrapperClass = `field-wrapper ${fieldWrapperClass}`

    if (error) formControlClass += ' error'

    if (money) inputClass += ' money'
    if (type === 'number' || money) {
      inputPattern += '\\d*'
      isNumberInput = true
    }

    const handleInputChange = (e) => {
      // TODO: hide show placeholder
      // if (placeholder)
      handleChange(e)
    }

    const handleFocusBlur = (focus, e) => {
      setFocused(focus)
      setIsEmpty(Boolean(e.target.value))
    }
    return (
      <div className={formControlClass} style={formControlStyle}>
        <label htmlFor="" className={labelClass} style={labelStyle}>
          {labelText}
        </label>
        <div className={fieldWrapperClass} style={fieldWrapperStyle}>
          {startAdornment}
          {isNumberInput ? (
            <>
              <NumberFormat
                ref={ref}
                thousandSeparator={'.'}
                decimalSeparator={','}
                className={inputClass}
                style={inputStyle}
                name={name}
                value={value}
                onChange={handleInputChange}
                onBlur={(e) => {
                  handleFocusBlur(false, e)
                }}
                onFocus={(e) => {
                  handleFocusBlur(true, e)
                }}
                required={required}
                pattern={inputPattern}
              />
              {money ? (
                <span className="text-mutted ml-8">{currencySign}</span>
              ) : null}
            </>
          ) : (
            <input
              ref={ref}
              type={type}
              onChange={handleInputChange}
              onBlur={(e) => {
                handleFocusBlur(false, e)
              }}
              onFocus={(e) => {
                handleFocusBlur(true, e)
              }}
              required={required}
              name={name}
              value={value}
              placeholder={placeholder}
            />
          )}
          {endAdornment}
        </div>
        <div className="helper-text">{error ? error : helperText}</div>
      </div>
    )
  }
)

export default Input
