import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import logo from '../../assets/img/logo.svg'
import { AuthContext } from './AuthContextProvider'
import LoginForm from './LoginForm'
import ForgottenPassForm from './ForgottenPassForm'
import PasswordResetForm from './PasswordResetForm'

const Login = () => {
  const { isAuthenticated, forgottenPass, passChange, auth } =
    useContext(AuthContext)

  if (isAuthenticated && !auth?.user?.is_new_user) return <Redirect to="/" />

  return (
    <div className="login-page">
      <div className="container fullWidth" style={{ maxWidth: 460 }}>
        <div className="grid">
          <div className="grid__container grid__container--column spacing-40">
            <div className="grid__item">
              <div className="logo --ta-center">
                <img src={logo} alt="Cachback logo" style={{ height: 35 }} />
              </div>
            </div>
            <div className="grid__item">
              {forgottenPass.isForgotten && <ForgottenPassForm />}
              {passChange.isChangingPassword && <PasswordResetForm />}
              {!forgottenPass.isForgotten && !passChange.isChangingPassword && (
                <LoginForm />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
