import React, { useContext, useEffect, useRef, useState } from 'react'
import Input from '../../components/acms-components/Input'
import Preloader from '../../components/acms-components/Preloader'
import { AuthContext } from './AuthContextProvider'
import { useForm } from './useForm'

const LoginForm = () => {
  const [loginInfo, handleChange] = useForm({ username: '', password: '' })
  const {
    login,
    errors,
    forgottenPass,
    setErrors,
    setForgottenPass,
    isLoading,
  } = useContext(AuthContext)

  const [agree, setAgree] = useState(false)
  const [agreeError, setAgreeError] = useState('')

  const inputRef = useRef()
  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    setAgreeError('')
    if (agree) login(loginInfo)
    else setAgreeError('Campo obrigatório')
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)} action="">
      <div className="grid__container grid__container--column spacing-16">
        {errors && errors.non_field_errors && (
          <div className="grid__item">
            <div className="p-16 mb-16 bg--alert light">
              {errors.non_field_errors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          </div>
        )}
        <div className="grid__item">
          <Input
            ref={inputRef}
            handleChange={handleChange}
            name="username"
            labelText="Email"
            value={loginInfo.username}
            error={errors?.username}
          />
        </div>
        <div className="grid__item">
          <Input
            type="password"
            handleChange={handleChange}
            name="password"
            labelText="Senha"
            value={loginInfo.password}
            error={errors?.password}
          />
        </div>
        <div className="grid__item">
          <p className="body_2">
            Esta aplicação foi desenvolvida e pertence às empresas do{' '}
            <b>Grupo Ponta Grande</b> e foi concebido, para ser usado pelos
            colaboradores das Empresas do Grupo estritamente para ser mostrado
            aos clientes destas.
          </p>
          <p className="body_2">
            Qualquer uso indevido fica sujeito a penalizações, coimas e a
            procedimento judicial contra o utilizador, que lhe dê outro destino
            que não o aqui expresso.
          </p>

          <input
            type="checkbox"
            id="agree"
            name="agree"
            onClick={() => setAgree(!agree)}
          />
          <label htmlFor="agree" className="body_2 --t-bold">
            Li e concordo com os termos acima
          </label>
          <div className="helper-text error">{agreeError}</div>
        </div>
        <div className="grid__item">
          <div className="action-btns --space-between">
            <button
              type="button"
              className="btn btn--primary btn--primary--link small"
              onClick={() => {
                setForgottenPass({ ...forgottenPass, isForgotten: true })
                setErrors({})
              }}
            >
              Esqueci a palavra passe
            </button>
            {isLoading ? (
              <Preloader />
            ) : (
              <button className="btn btn--primary raised">Entrar</button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default LoginForm
