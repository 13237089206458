import React, { useState } from 'react'

const Response = ({ type = 'success', content }) => {
  const [isShowing, setIsShowing] = useState(true)
  if (!isShowing) return null
  return (
    <div className="p-16 mb-16 bg--alert light">
      <div className="close-btn" onClick={() => setIsShowing(false)}></div>
      {content}
    </div>
  )
}

export default Response
