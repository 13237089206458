import React from 'react'
import preloader from '../../assets/img/preloader.gif'

const Preloader = ({ width = 48, height = 48 }) => (
  <img
    src={preloader}
    alt="preloader"
    style={{ width: width, height: height, display: 'inline-block' }}
  />
)

export default Preloader
